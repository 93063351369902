<template>
  <div class="container-sm mb-3 py-5">
    <h1 class="text-center">
      <font-awesome-icon :icon="['far', 'frown']" /><br />
      Sorry
    </h1>
    <p class="text-center">
      There was a problem with your payment. We are reviewing your order.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Failure",
  mounted() {
    if (this.order.success != "") {
      this.resetOrder();
    } else {
      this.$router.replace("/");
    }
  },
  computed: {
    ...mapGetters({
      order: "order",
      user: "user"
    })
  },
  head: {
    title: {
      inner: 'Failed payment',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Failed payment | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Failed payment | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Failed payment | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    resetOrder() {
      this.$store.dispatch("fetchOrder");
      this.$store.dispatch("fetchOrderSuccess");
    }
  }
};
</script>

<style lang="scss" scoped></style>
