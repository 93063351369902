<template>
  <svg viewBox="0 0 156 30">
    <path
      d="M150.036 10.717c-1.38 0-2.679.52-3.667 1.326-.987-.805-2.286-1.326-3.666-1.326-.91 0-1.769.209-2.522.599a1.893 1.893 0 00-1.248-.468 1.952 1.952 0 00-1.948 1.974v8.942c0 1.145.936 2.081 2.053 2.081a2.085 2.085 0 002.078-2.08v-5.33c0-.885.703-1.587 1.587-1.587.86 0 1.589.702 1.589 1.586v5.33c0 1.145.96 2.081 2.077 2.081a2.085 2.085 0 002.078-2.08v-5.33c0-.885.729-1.587 1.589-1.587.884 0 1.587.702 1.587 1.586v5.33c0 1.145.96 2.081 2.078 2.081 1.117 0 2.079-.936 2.079-2.08v-5.33c0-3.145-2.598-5.718-5.744-5.718zM98.153 10.526c-.687-.216-1.7-.468-1.88.326-.18.722 1.12 1.083 1.663 1.192 1.084.217 2.132.83 2.891 1.661 1.664 1.733 2.025 4.478 1.085 6.646-.976 2.239-3.217 3.575-5.603 3.72-1.338.072-4.121-.29-5.241-1.77-1.41-1.878-.037-4.514 2.24-4.406.904.036 1.881.722 2.64.759 1.301.107 1.59-1.481.361-1.807-2.277-.685-3.94-1.589-4.988-3.684-.76-1.589-.579-3.575.506-5.02C93.562 5.796 96.85 5 99.563 6.048c1.445.578 2.639 2.059 1.734 3.649-.65 1.083-1.771 1.3-3.144.83zM108.136 21.948a1.858 1.858 0 11-3.715 0c0-1.014.832-1.845 1.845-1.845 1.04 0 1.87.831 1.87 1.845zM116.786 24.01c-3.663 0-6.65-2.99-6.65-6.65 0-3.662 2.987-6.651 6.65-6.651 1.48 0 2.883.467 4.052 1.377.908.702 1.091 2.003.39 2.886-.702.91-2 1.091-2.91.391a2.486 2.486 0 00-1.532-.52 2.538 2.538 0 00-2.52 2.516 2.538 2.538 0 002.52 2.517c.57 0 1.09-.183 1.532-.52.91-.701 2.208-.52 2.91.39.674.909.518 2.21-.39 2.886a6.529 6.529 0 01-4.052 1.377zM129.332 10.717c-3.666 0-6.654 2.988-6.654 6.654 0 3.668 2.988 6.655 6.654 6.655s6.654-2.987 6.654-6.655c0-3.666-2.988-6.654-6.654-6.654zm0 4.131a2.51 2.51 0 012.523 2.523 2.51 2.51 0 01-2.523 2.525 2.51 2.51 0 01-2.523-2.525 2.51 2.51 0 012.523-2.523z"
      fill="#313140"
      fill-rule="nonzero"
    />
    <path
      d="M7.84 18.04h-.542c-1.049 0-1.519-.87-1.519-1.774v-5.499h2.64c1.41 0 2.53-1.118 2.53-2.492a2.513 2.513 0 00-2.53-2.527h-2.64V2.892A2.9 2.9 0 002.891 0 2.9 2.9 0 000 2.892v13.374c0 4.193 3.034 7.551 7.297 7.551h.543c1.59 0 2.89-1.3 2.89-2.89a2.898 2.898 0 00-2.89-2.888z"
      fill="#49c5b1"
      fill-rule="nonzero"
    />
    <path
      d="M24.372 6.162c3.542 1.3 6.08 4.707 6.08 8.691 0 5.098-4.154 9.252-9.252 9.252-.275 0-.546-.012-.815-.036l5.543-9.615a.903.903 0 00-.781-1.35h-2.665l1.89-6.942zm-6.343 17.382c-3.543-1.299-6.081-4.706-6.081-8.69 0-5.099 4.154-9.253 9.252-9.253.275 0 .547.012.815.036l-5.543 9.615a.903.903 0 00.781 1.351h2.665l-1.889 6.941z"
      fill="#49c5b1"
    />
    <path
      d="M51.021 14.71c0-5.098-4.154-9.25-9.252-9.25a9.544 9.544 0 00-4.52 1.155 2.692 2.692 0 00-2.023-.867 2.728 2.728 0 00-2.709 2.709v18.288c0 1.483 1.192 2.676 2.637 2.748h.253a2.861 2.861 0 002.853-2.855v-3.362a9.493 9.493 0 003.509.686c5.098 0 9.252-4.153 9.252-9.251zm-5.743 0a3.49 3.49 0 01-3.51 3.509 3.489 3.489 0 01-3.508-3.508 3.489 3.489 0 013.509-3.509 3.49 3.49 0 013.51 3.509z"
      fill="#49c5b1"
      fill-rule="nonzero"
    />
    <path
      d="M61.009 24.106a7.99 7.99 0 01-7.987-7.987V8.602c0-1.553 1.265-2.854 2.892-2.854a2.862 2.862 0 012.856 2.854v7.517a2.251 2.251 0 002.239 2.242 2.251 2.251 0 002.238-2.242V8.602a2.862 2.862 0 012.856-2.854c1.627 0 2.892 1.301 2.892 2.854v7.517a7.99 7.99 0 01-7.986 7.987zM89.498 14.71c0-5.098-4.154-9.25-9.251-9.25a9.545 9.545 0 00-4.521 1.155 2.692 2.692 0 00-2.022-.867 2.73 2.73 0 00-2.71 2.709v18.288c0 1.483 1.192 2.676 2.638 2.748h.252a2.862 2.862 0 002.854-2.855v-3.362a9.483 9.483 0 003.509.686c5.097 0 9.25-4.153 9.25-9.251zm-5.743 0a3.489 3.489 0 01-3.508 3.509 3.488 3.488 0 01-3.509-3.508 3.488 3.488 0 013.509-3.509 3.489 3.489 0 013.508 3.509z"
      fill="#313140"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script>
export default {
  props: {}
};
</script>
