<template>
  <div class="container-sm mb-3">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <div class="alert alert-danger" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <form class="form" @submit.prevent="handleSubmit(signUp)">
        <div class="form-group">
          <ValidationProvider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
            slim
          >
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Enter your email address"
              v-model="email"
            />
            <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider
            name="Password"
            rules="required"
            v-slot="{ errors }"
            slim
          >
            <label for="password">Password</label>
            <div class="position-relative">
              <input
                :type="!showPassword ? 'password' : 'text'"
                class="form-control"
                id="password"
                placeholder="Enter your password"
                v-model="password"
              />
              <button
                type="button"
                class="typetoggle btn btn-sm h-100 font-weight-bold position-absolute"
                v-text="showPassword ? 'HIDE' : 'SHOW'"
                @click="showPassword = !showPassword"
              ></button>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>
        <div class="custom-control custom-checkbox mb-3">
          <input
            type="checkbox"
            class="custom-control-input"
            id="marketing"
            v-model="marketing"
          />
          <label class="custom-control-label" for="marketing"
            >I agree to topups.com contacting me through email for marketing
            purposes.</label
          >
        </div>

        <button
          type="submit"
          class="btn btn-outline-primary btn-block mb-3"
          :disabled="submitting"
        >
          <span v-if="!submitting">Next</span>
          <span v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Registering...
          </span>
        </button>
      </form>
    </ValidationObserver>

    <p class="text-center">
      By signing up, you agree to our
      <a href=""><u>Terms &amp; Conditions</u></a
      >, <a href=""><u>Privacy Policy</u></a> and
      <a href=""><u>Cookies Policy</u></a
      >.
    </p>

    <hr />

    <router-link to="/login" class="btn btn-outline-primary btn-block"
      >Log In</router-link
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      marketing: false,
      errorMessage: "",
      showPassword: false,
      submitting: false
    };
  },
  // mounted () {
  //   this.order = JSON.parse(sessionStorage.getItem('order'));
  // },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  head: {
    title: {
      inner: 'Secure online top-up service, register now',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Secure online top-up service, register now | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Secure online top-up service, register now | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Secure online top-up service, register now | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    signUp() {
      this.submitting = true;
      this.$firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(registered => {
          this.$http({
            method: "post",
            url: `${process.env.VUE_APP_API_URL}auth/register`,
            data: {
              user: {
                uid: registered.user.uid,
                email: registered.user.email,
                displayName: registered.user.displayName,
                photoURL: registered.user.photoURL,
                phoneNumber: registered.user.phoneNumber,
                marketing: this.marketing
              },
              order: this.order != null ? this.order.id : null
            }
          }).then(
            () => {
              this.submitting = false;
              this.$router.push("/register-complete");
            },
            error => {
              console.error(error);
            }
          );
        })
        .catch(error => {
          // Handle Errors here.
          this.submitting = false;
          let errorCode = error.code;
          if (errorCode) {
            this.errorMessage = error.message;
          }
          // ...
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.typetoggle {
  top: 0;
  right: 0em;
  user-select: none;
}
</style>
