<template>
  <div>
    <div class="container-sm mb-3" v-if="orderData.network">
      <div class="card border-0 mb-3" style="max-width: 540px;">
        <div class="row no-gutters align-items-center">
          <div class="col-md-4">
            <img
              :src="orderData.network.LogoUrl.replace('png', 'svg')"
              :onerror="'this.src=' + orderData.network.LogoUrl"
              class="card-img img-thumbnail py-4"
              :alt="orderData.network.Name"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">
                <b
                  >{{ orderData.currency.symbol
                  }}{{ orderData.value | currency }}
                  <span
                    class="text-capitalize"
                    v-text="orderData.network.Name"
                  ></span>
                  to +{{ orderData.number }}</b
                >
              </h5>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th class="px-0" scope="col" colspan="2">Checkout</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="pl-0" scope="row">Subtotal</td>
            <td class="text-right pr-0">
              {{ checkout.currency.symbol }}{{ checkout.value | currency }}
            </td>
          </tr>
          <tr>
            <td class="pl-0" scope="row">Service fee</td>
            <td class="text-right pr-0">{{ checkout.currency.symbol }}{{ serviceFee }}</td>
          </tr>
          <tr class="font-weight-bold">
            <td class="pl-0" scope="row">Total to pay</td>
            <td class="text-right pr-0">
              {{ checkout.currency.symbol }}{{ Math.abs(checkout.value)+Math.abs(serviceFee) | currency }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <h6 class="card-title"><b>How do you want to pay?</b></h6> -->
      <h6 class="card-title mb-4"><b>Pay now</b></h6>
      <div class="row">
        <!-- <div class="col-6">
          <button type="button" class="btn btn-outline-primary btn-block">Cryptocurrency</button>
        </div> -->
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-block text-white"
            @click="nextStep('worldpay')"
          >
            <b>Debit/Credit Card</b>
          </button>
        </div>
      </div>
    </div>
    <div
      class="details position-fixed d-flex flex-column justify-content-end h-100 w-100"
      @click.self="showDetails = false"
      :class="{ show: showDetails }"
    >
      <div class="bg-white w-100 p-3">
        <h4 class="text-center mb-4">Pay by card</h4>
        <ValidationObserver v-slot="{ handleSubmit }" slim>
          <form
            action="/complete"
            id="paymentForm"
            method="post"
            @submit.prevent="handleSubmit(getToken)"
            ref="form"
          >
            <span id="paymentErrors"></span>
            <div
              class="form-inline justify-content-center align-items-baseline"
            >
              <label for="postcode" class="mx-2">Zip/Postal Code</label>
              <div class="form-group flex-column">
                <ValidationProvider
                  name="Zip/Postal Code"
                  rules="required"
                  v-slot="{ errors }"
                  class="w-100 mb-2"
                  slim
                >
                  <input
                    id="postcode"
                    type="text"
                    class="form-control form-control-sm w-100"
                    v-model="postcode"
                    placeholder="CUSTOMER POSTCODE"
                  />
                  <small class="text-reset d-block">{{ errors[0] }}</small>
                </ValidationProvider>
                <ValidationProvider
                  name="Country"
                  rules="required"
                  v-slot="{ errors }"
                  class="w-100"
                  slim
                >
                  <select
                    class="custom-select custom-select-sm w-100"
                    v-model="countryIso"
                  >
                    <option value="null" hidden disabled selected
                      >Select your country</option
                    >
                    <option
                      :value="country.iso"
                      v-for="(country, index) in countries"
                      :key="index"
                      >{{ country.name }}</option
                    >
                  </select>
                  <small class="text-reset d-block">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>
            </div>
            <div
              id="paymentSection"
              class="d-flex justify-content-center"
            ></div>
            <button class="btn btn-primary btn-block btn-lg text-white" :disabled="submitting">
              <span v-if="!submitting"><b>Make payment</b></span>
              <span v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Processing...
              </span>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      submitting: false,
      showDetails: false,
      serviceFee: 0.99,
      paymentMethod: "",
      orderData: {},
      checkout: {},
      token: "",
      countries: [],
      countryIso: null,
      postcode: "",
      clientKey: process.env.VUE_APP_WORLDPAY_CLIENT
    };
  },
  filters: {
    currency(val) {
      return Math.abs(val).toFixed(2);
    }
  },
  async mounted() {
    this.$http({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}countries`
    }).then(
      result => {
        const countries = result.data.countries;
        countries.forEach(country => {
          if (country.CountryName != undefined) {
            this.countries.push({
              iso: country.CountryIso,
              name: country.CountryName
            });
          }
        });
      },
      error => {
        console.error(error);
      }
    );

    if (this.user.data[0] != null) {
      await this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}auth/account-status?email=${this.user.data[0].email}`
      }).then(
        result => {
          let status = result.data.status;
          this.$store.dispatch("fetchUser", this.user);

          if (status == "incomplete") {
            this.$router.replace("/register-complete");
          } else if (status == "disabled") {
            this.$router.replace("/login");
          } else {
            this.getOrder();
          }
        },
        error => {
          console.error(error);
        }
      );
    } else {
      this.$router.replace("/login");
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  head: {
    title: {
      inner: 'Secure checkout when buying online',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Secure checkout when buying online | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Secure checkout when buying online | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Secure checkout when buying online | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    getOrder() {
      if (this.order != null && this.order.id) {
        let that = this;

        this.$http({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}order/fetch?id=${this.order.id}`
        }).then(
          result => {
            let order = result.data.order;
            if (order.currency.iso != "GBP") {
              order.value = order.product.Maximum.ReceiveValue;
            }
            if (order.checkout == undefined) {
              this.checkout = {
                value: order.value,
                currency: order.currency
              };
            } else {
              this.checkout = order.checkout;
            }
            this.orderData = order;

            window.Worldpay.useTemplateForm({
              clientKey: that.clientKey,
              form: "paymentForm",
              paymentSection: "paymentSection",
              display: "inline",
              reusable: true,
              saveButton: false,
              type: "card",
              iframeHolderInline:
                "position: relative;top: 0px;background-color: #fff;border:none;",
              templateOptions: {
                dimensions: {
                  width: "340px",
                  height: "300px"
                },
                images: { enabled: false }
              },
              callback: function(obj) {
                if (obj && obj.token) {
                  that.token = obj.token;
                  that.submitCardDetails();
                }
              }
            });
          },
          error => {
            console.error(error);
          }
        );
      } else {
        this.$router.replace({ name: "Home" });
      }
    },
    nextStep(method) {
      this.paymentMethod = method;
      this.showDetails = true;
    },
    updateOrder(payment) {
      this.$http({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}order/complete`,
        data: {
          id: this.order.id,
          payment: payment
        }
      }).then(
        () => {
          if (payment.paymentStatus == "SUCCESS") {
            this.$gtm.trackEvent({
              event: "Transaction",
              ecommerce: {
                purchase: {
                  actionField: {
                    id: this.order.id, // Transaction ID. Required for purchases and refunds.
                    revenue: this.orderData.value, // Total transaction value (incl. tax and shipping)
                    tax: this.orderData.value * 0.2,
                    shipping: this.serviceFee
                  },
                  products: [
                    {
                      // List of productFieldObjects.
                      name:
                        this.orderData.value +
                        " " +
                        this.orderData.network.Name, // Name or ID is required.
                      id: this.orderData.product.SkuCode,
                      price: Math.abs(this.checkout.value)+Math.abs(this.serviceFee),
                      brand: this.orderData.network.Name,
                      category: "Topup/Recharge",
                      variant:
                        this.orderData.product.RedemptionMechanism !=
                        "Immediate"
                          ? "Pin"
                          : "Immediate",
                      quantity: 1
                    }
                  ]
                }
              }
            });
            this.$router.push("/success");
          } else {
            this.$router.push("/failure");
          }
        },
        error => {
          this.submitting = false;
          console.error(error);
        }
      );
    },
    getToken() {
      window.Worldpay.submitTemplateForm();
    },
    submitCardDetails() {
      let that = this;
      this.submitting = true;
      this.$http({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}checkout/worldpay`,
        data: {
          desc:
            this.orderData.currency.symbol +
            "" +
            this.orderData.value +
            " topup",
          token: this.token,
          value: Math.abs(this.checkout.value)+Math.abs(this.serviceFee),
          iso: this.checkout.currency.iso,
          order: this.order.id,
          billing: {
            postcode: this.postcode,
            countryCode: this.countryIso
          },
          email: this.user.data[0].email
        }
      }).then(
        result => {
          that.$store.dispatch("fetchOrderSuccess", result.data.paymentStatus);
          return that.updateOrder(result.data);
        },
        error => {
          that.submitting = false;
          console.error(error);
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-img {
  max-height: 100px;
  object-fit: contain;
}
.details {
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 2;
  background-color: rgba(#000000, 0.5);
  transition: all 0.2s ease-in-out;
  > div {
    transform: translateY(0);
    border-radius: 1rem 1rem 0 0;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.show) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    > div {
      transform: translateY(100%);
    }
  }
}
.form-control::placeholder {
  color: #ccc;
}
</style>
