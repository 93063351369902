import Vue from "vue";
import VueRouter from "vue-router";
import VueGtm from "vue-gtm";
import store from "../store";

// import Home from "../views/Home.vue";
// import Network from "../views/Network.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import RegisterComplete from "../views/RegisterComplete.vue";
import Checkout from "../views/Checkout.vue";
import Account from "../views/Account.vue";
import Success from "../views/Success.vue";
import Failure from "../views/Failure.vue";
// import Page from "../views/Page.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: "Fastest mobile top-up service, buy online at Topups.com"
    }
  },
  {
    path: "/topup",
    name: "Topup",
    component: () =>
      import(/* webpackChunkName: "countries" */ "../views/Topup.vue")
  },
  { path: '/([a-z]{2}-[a-z]{2})/countries', redirect: { path: '/topup/countries' }},
  { path: '/([a-z]{2}-[a-z]{2})/networks', redirect: { path: '/topup' }},
  { path: '/([a-z]{2}-[a-z]{2})/:name', redirect: { path: '/topup/redirected/:name' }},
  {
    path: "/topup/countries",
    name: "Countries",
    component: () =>
    import(/* webpackChunkName: "countries" */ "../views/Countries.vue") 
  },
  {
    path: "/topup/:country",
    name: "Networks",
    component: () =>
    import(/* webpackChunkName: "networks" */ "../views/Networks.vue")
  },
  {
    path: "/topup/:country/:name",
    name: "Network",
    component: () =>
    import(/* webpackChunkName: "network" */ "../views/Network.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/register-complete",
    name: "RegisterComplete",
    component: RegisterComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/failure",
    name: "Failure",
    component: Failure,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/:slug",
    name: "Page",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/Page.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueGtm, {
  id: "GTM-NNXSCTV", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: process.env.NODE_ENV != "development", // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  debug: process.env.NODE_ENV == "development",
  vueRouter: router,
  trackOnNextTick: true
});

router.beforeEach(async (to, from, next) => {
  if (to.params.slug) {
    await Vue.prototype.$http({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}pages/page?slug=${to.params.slug}`
    }).then(
      result => {
        let page = result.data.page;
        if (page == null) {
          return next({ name: "Topup" });
        } 
        to.params.page = page;
      },
      error => {
        console.error(error);
      }
    );
  }

  if (to.path == '/topup/countries') {
    await Vue.prototype.$http({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}countries`
    }).then(
      result => {
        to.params.countries = result.data.countries;
      },
      error => {
        console.error(error);
      }
    );
  }

  if (to.name == 'Network') {
    await Vue.prototype.$http({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}products/fetch?providerName=${to.params.name}`
    }).then(
      result => {
        to.params.products = [];
        let maxValue = result.data.Items[0].Maximum.ReceiveValueExcludingTax;
        let minValue = result.data.Items[0].Minimum.ReceiveValueExcludingTax;
        let maxSendValue = result.data.Items[0].Maximum.SendValue;
        let minSendValue = result.data.Items[0].Minimum.SendValue;
        if (result.data.Items.length == 1 && maxValue != minValue) {
          const difference = Math.abs(maxValue - minValue);
          const sendDifference = Math.abs(maxSendValue - minSendValue);
          const portion = Math.floor(difference / 12);
          const sendPortion = Math.floor(sendDifference / 12);
          for (let i = 11; i >= 0; i--) {
            to.params.products.push({
              Maximum: {
                ReceiveValue: Math.floor(minValue),
                ReceiveCurrencyIso:
                  result.data.Items[0].Maximum.ReceiveCurrencyIso,
                SendValue: Math.ceil(minSendValue)
              },
              UatNumber: result.data.Items[0].UatNumber,
              SkuCode: result.data.Items[0].SkuCode,
              ProviderCode: result.data.Items[0].ProviderCode,
              RegionCode: result.data.Items[0].RegionCode,
              Checkout: result.data.Items[0].Checkout
            });
            minValue = minValue + portion;
            minSendValue = minSendValue + sendPortion;
          }
        } else {
          to.params.products = result.data.Items;
        }
        to.params.rate = result.data.Rate;
        if (result.data.Provider.meta == undefined || Object.keys(result.data.Provider.meta).length === 0) {
          result.data.Provider.meta = {
            content: '',
            title: 'Select your top-up amount for '+result.data.Provider.Name,
            description: 'Fast online topup for '+result.data.Provider.Name+' using topups.com online recharge service'
          }
        }
        to.params.provider = result.data.Provider;
        to.params.UatNumber = result.data.Items[0].UatNumber;
        if (result.data.Currency != null) {
          to.params.currency = result.data.Currency;
        }
      },
      error => {
        console.error(error);
      }
    );
  }
 
  let user = store.state.user;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user.data == null) {
      return next({ name: "Login" });
    } else if (
      user.data.status != "incomplete" &&
      to.path != "/register-complete"
    ) {
      await Vue.prototype.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}auth/account-status?email=${user.data[0].email}`
      }).then(
        result => {
          let status = result.data.status;
          user.registration.status = status;
          store.dispatch("fetchUser", user);
          if (status == "incomplete") {
            return next({ path: "/register-complete" });
          }
        },
        error => {
          console.error(error);
        }
      );
    }
    return next();
  } else {
    if (user.data != null && to.path == "/login") {
      return next({ name: "Account" });
    } else {
      return next(); // does not require auth, make sure to always call next()!
    }
  }
});

export default router;
