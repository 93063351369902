<template>
  <div class="container-sm mb-3">
    <ValidationObserver v-slot="{ handleSubmit }" slim tag="div" class="mb-3">
      <div class="alert alert-danger" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <form class="form" @submit.prevent="handleSubmit(login)">
        <div class="form-group">
          <ValidationProvider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
            slim
          >
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Enter your email address"
              v-model="email"
            />
            <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider
            name="Password"
            rules="required"
            v-slot="{ errors }"
            slim
          >
            <label for="password">Password</label>
            <div class="position-relative">
              <input
                :type="!showPassword ? 'password' : 'text'"
                class="form-control"
                id="password"
                placeholder="Enter your password"
                v-model="password"
              />
              <button
                type="button"
                class="typetoggle btn btn-sm h-100 font-weight-bold position-absolute"
                v-text="showPassword ? 'HIDE' : 'SHOW'"
                @click="showPassword = !showPassword"
              ></button>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </div>
        <p class="small"><a href="">Forgot your password?</a></p>

        <button
          type="submit"
          class="btn btn-outline-primary btn-block"
          :disabled="submitting"
        >
          <span v-if="!submitting">Log In</span>
          <span v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Verifying...
          </span>
        </button>
        <div class="d-flex align-items-center">
          <hr class="flex-grow-1" />
          <b class="mx-4">OR</b>
          <hr class="flex-grow-1" />
        </div>

        <p>Don't have an account?</p>
        <router-link to="/register" class="btn btn-outline-primary btn-block"
          >Sign Up</router-link
        >
      </form>
    </ValidationObserver>
    <section id="firebaseui-auth-container"></section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as firebaseui from "firebaseui";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  data() {
    return {
      submitting: false,
      email: "",
      password: "",
      errorMessage: "",
      showPassword: false,
      successUrl: "/account"
    };
  },
  mounted() {
    this.successUrl =
      this.order != null && this.order.id != undefined
        ? "/checkout"
        : "/account";

    let ui;

    if (firebaseui.auth.AuthUI.getInstance()) {
      ui = firebaseui.auth.AuthUI.getInstance();
    } else {
      ui = new firebaseui.auth.AuthUI(this.$firebase.auth());
    }

    ui.start("#firebaseui-auth-container", {
      signInFlow: "popup",
      callbacks: {
        signInSuccessWithAuthResult: async authResult => {
          // User successfully signed in.
          if (this.order != null) {
            await this.$http({
              method: "post",
              url: `${process.env.VUE_APP_API_URL}auth/register`,
              data: {
                user: authResult.user,
                order: this.order.id
              }
            }).then(
              result => {
                console.log(result);
              },
              error => {
                console.error(error);
              }
            );
          }

          await this.$http({
            method: "get",
            url: `${process.env.VUE_APP_API_URL}auth/account-status?email=${authResult.user.email}`
          }).then(
            result => {
              let status = result.data.status;
              this.user.registration.status = status;
              this.$store.dispatch("fetchUser", this.user);
            },
            error => {
              console.error(error);
            }
          );
          return false;
        }
      },
      signInSuccessUrl: this.successUrl,
      signInOptions: [
        // TODO firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        this.$firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // TODO firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ]
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  head: {
    title: {
      inner: 'Secure online top-up service, login to buy online',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Secure online top-up service, login to buy online | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Secure online top-up service, login to buy online | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Secure online top-up service, login to buy online | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    login() {
      let that = this;
      this.submitting = true;
      this.$firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async response => {
          await this.$http({
            method: "get",
            url: `${process.env.VUE_APP_API_URL}auth/account-status?email=${response.user.email}`
          }).then(
            result => {
              let status = result.data.status;
              that.user.registration.status = status;
              that.$store.dispatch("fetchUser", that.user);
            },
            error => {
              console.error(error);
            }
          );
          if (that.order && that.order.id) {
            await this.$http({
              method: "post",
              url: `${process.env.VUE_APP_API_URL}order/add-edit`,
              data: {
                id: that.order.id,
                order: {
                  sender: that.user.data
                }
              }
            }).then(
              () => {
                that.$router.push("/checkout");
              },
              error => {
                console.error(error);
              }
            );
          } else {
            that.$router.push("/account");
          }
        })
        .catch(error => {
          // Handle Errors here.
          that.submitting = false;
          let errorCode = error.code;
          if (errorCode) {
            that.errorMessage = error.message;
          }
          // ...
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import url(../../node_modules/firebaseui/dist/firebaseui.css);
.typetoggle {
  top: 0;
  right: 0em;
  user-select: none;
}
</style>
