import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://3f9b34e5176342709aec3a1683807dfa@o250054.ingest.sentry.io/5480564",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true
    }),
    new Integrations.BrowserTracing()
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import axios from "axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUserCircle,
  faBolt,
  faHeart,
  faLock,
  faShoppingBasket
} from "@fortawesome/pro-solid-svg-icons";
import {
  faFrown,
  faSmile,
  faEnvelope,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faTimes
} from "@fortawesome/pro-light-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faLinkedin,
  faBars,
  faUserCircle,
  faFrown,
  faSmile,
  faBolt,
  faHeart,
  faLock,
  faEnvelope,
  faTimes,
  faShoppingBasket
);

import firebase from "firebase/app";
import "firebase/auth";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueHead from "vue-head";

Vue.use(VueHead);

// Register it globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$env = process.env.NODE_ENV;

Vue.prototype.$firebase = firebase;
Vue.prototype.$http = axios;
Vue.prototype.$firebase.initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE));

Vue.prototype.$firebase.auth().onAuthStateChanged(user => {
  const userData = user != null ? user.providerData : null;
  store.dispatch("setUser", userData);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
