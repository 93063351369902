import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// function updateStorage(state,value) {
//   let stored = JSON.parse(localStorage.getItem('user'));
//   if (stored == null) {
//     localStorage.setItem('user',JSON.stringify({[state]:value}));
//   } else {
//     stored[state] = value;
//     localStorage.setItem('user',JSON.stringify(stored));
//   }
// }

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      registration: {
        status: "incomplete"
      }
    },
    order: {
      id: null
    },
    country: {
      CountryIso: null,
      CountryName: null
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
      // updateStorage('loggedIn', value);
    },
    SET_DATA(state, data) {
      state.user.data = data;
    },
    SET_USER(state, data) {
      state.user = data;
      // updateStorage('data', data);
    },
    SET_ORDER(state, id) {
      state.order.id = id;
    },
    SET_ORDER_SUCCESS(state, value) {
      state.order.success = value;
    },
    SET_COUNTRY(state, country) {
      state.country = country;
    },
  },
  actions: {
    setUser({ commit }, data) {
      commit("SET_LOGGED_IN", data != undefined);
      commit("SET_DATA", data);
    },
    fetchUser({ commit }, user) {
      // commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit(
          "SET_USER",
          user
          // displayName: user.displayName,
          // email: user.email,
          // photo: user.photoURL
        );
      } else {
        commit("SET_USER", store.state.user);
      }
    },
    fetchOrder({ commit }, id) {
      if (id) {
        commit("SET_ORDER", id);
      } else {
        commit("SET_ORDER", null);
      }
    },
    fetchOrderSuccess({ commit }, value) {
      if (value) {
        commit("SET_ORDER_SUCCESS", value);
      } else {
        commit("SET_ORDER_SUCCESS", null);
      }
    },
    setCountry({ commit }, data) {
      commit("SET_COUNTRY", data);
    }
  },
  modules: {},
  getters: {
    user(state) {
      return state.user;
    },
    order(state) {
      return state.order;
    },
    country(state) {
      return state.country;
    }
  },
  plugins: [createPersistedState()]
});

export default store;
