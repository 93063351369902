<template>
  <div class="container-sm mb-3">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <!-- <div class="alert alert-danger" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div> -->
      <form class="form">
        <label for="userName">Create a username</label>
        <ValidationProvider
          name="Username"
          :rules="{ required: true, min: 4 }"
          v-slot="{ errors }"
          slim
        >
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">topups.com/@</span>
            </div>
            <input
              type="text"
              class="form-control"
              id="userName"
              placeholder="Username"
              v-model="userName"
            />
            <small class="form-text text-muted w-100" v-if="userExists"
              >Sorry username <b>{{ userName }}</b> already taken.</small
            >
            <small class="form-text text-danger w-100">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Title"
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
        >
          <div class="form-group">
            <label for="title" class="d-block">Title</label>
            <select class="custom-select w-auto" v-model="title">
              <option value="" selected hidden disabled>Select title</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
            </select>
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="First Name"
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
        >
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              placeholder="Enter your first name"
              v-model="firstName"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Last Name"
          :rules="{ required: true }"
          v-slot="{ errors }"
          slim
        >
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              class="form-control"
              id="lastName"
              placeholder="Enter your last name"
              v-model="lastName"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <label>Date of Birth</label>
        <div class="d-flex mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Day</span>
          </div>
          <div class="input-group-prepend">
            <select class="custom-select" v-model="dob.day">
              <option :value="index" v-for="index in 31" :key="index">{{
                index
              }}</option>
            </select>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">Month</span>
          </div>
          <div class="input-group-prepend">
            <select class="custom-select" v-model="dob.month">
              <option
                :value="index"
                v-for="(month, index) in dob.months"
                :key="index"
                >{{ month }}</option
              >
            </select>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">Year</span>
          </div>
          <div class="input-group-prepend">
            <select class="custom-select" v-model="dob.year">
              <option
                :value="minYear - index"
                v-for="index in 100"
                :key="index"
                >{{ minYear - index }}</option
              >
            </select>
          </div>
        </div>
        <div v-if="verified == null">
          <label for="mobileNumber">Mobile Number</label>
          <div class="input-group flex-nowrap mb-3">
            <select
              class="custom-select input-group-prepend flex-grow-0 w-auto"
              v-model="prefix"
              @change="updateValidation"
            >
              <option
                :value="country.prefix"
                v-for="(country, index) in countries"
                :key="index"
                >+{{ country.prefix }}</option
              >
            </select>
            <ValidationProvider
              class="flex-grow-1"
              name="Mobile Number"
              :rules="{ required: true, min: dialingMin }"
              v-slot="{ errors }"
              slim
            >
              <input
                type="tel"
                class="form-control border-rounded-left-0"
                id="mobileNumber"
                placeholder="Enter your mobile number"
                v-model="mobileNumber"
                v-on:keydown.32.prevent
                ref="verify"
              />
              <small class="form-text text-muted w-100" v-if="numberExists"
                >Sorry number registered on existing account.</small
              >
              <small class="form-text text-danger w-100" v-if="failedValidation">Unable to validate. Please check and try again.</small>
              <small class="form-text text-danger w-100">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
          <p>
            <small class="form-text text-muted"
              >We will send a 6 Digit Code by SMS to your phone.</small
            >
          </p>
          <button
            class="btn btn-outline-primary btn-block mb-3"
            @click.prevent="handleSubmit(sendVerify)"
            :disabled="sending"
          >
            <span v-if="!sending">Send Confirmation</span>
            <span v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Sending...
            </span>
          </button>
        </div>
        <div v-else>
          <div v-if="verified != 'approved'">
            <p class="text-center">
              We sent a 6 Digit Code to {{ prefix + mobileNumber }}
            </p>
            <ValidationProvider
              name="6 Digit Code"
              :rules="{ required: true, length: 6, integer: true }"
              v-slot="{ errors }"
              slim
            >
              <div class="form-group">
                <label for="code">Enter Code</label>
                <input
                  type="number"
                  class="form-control"
                  id="code"
                  placeholder="Enter 6 Digit Code"
                  v-model="code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <button
              type="button"
              class="btn btn-outline-primary btn-block mb-3"
              @click="verifyCode"
              :disabled="verifying"
            >
              <span v-if="!verifying">Verify</span>
              <span v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Verifying...
              </span>
            </button>
          </div>
          <div v-else>
            <p class="text-center">Number verified successfully.</p>
            <button
              class="btn btn-outline-primary btn-block mb-3"
              @click.prevent="handleSubmit(completeSignUp)"
              :disabled="submitting"
            >
              <span v-if="!submitting">Submit Registration</span>
              <span v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Registering...
              </span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { extend } from "vee-validate";
import { length, integer, min } from "vee-validate/dist/rules";

extend("length", length);
extend("integer", integer);
extend("min", min);

export default {
  data() {
    return {
      userName: "",
      userExists: null,
      title: "",
      firstName: "",
      lastName: "",
      timer: null,
      dob: {
        months: [],
        minYear: null,
        day: null,
        month: null,
        year: null
      },
      countries: [],
      location: null,
      // regex: null,
      dialingMin: null,
      prefix: null,
      mobileNumber: null,
      numberExists: false,
      failedValidation: false,
      sending: false,
      code: "",
      verified: null,
      verifying: false,
      submitting: false
    };
  },
  watch: {
    userName: function(val) {
      this.userExists = null;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.checkUserName({ query: val });
      }, 800);
    }
  },
  created() {
    const today = new Date();
    this.minYear = today.getFullYear() - 12;
    this.dob.months = Array.from({ length: 12 }, (e, i) => {
      return new Date(null, i + 1, null).toLocaleDateString("en", {
        month: "long"
      });
    });
  },
  mounted() {
    this.$http({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}countries`
    }).then(
      result => {
        // let regex = result.data.kyc.networks[0].ValidationRegex;
        let countries = result.data.countries;
        this.prefix =
          result.data.kyc.country.InternationalDialingInformation[0].Prefix;
        // this.regex = regex.replace(this.prefix, '');
        this.dialingMin =
          result.data.kyc.country.InternationalDialingInformation[0]
            .MaximumLength -
          this.prefix.length -
          1;
        countries.forEach(country => {
          country.InternationalDialingInformation.forEach(prefix => {
            if (prefix != undefined) {
              this.countries.push({
                prefix: prefix.Prefix,
                maxlength: prefix.MaximumLength
              });
            }
          });
        });
        this.countries.sort((a, b) =>
          a.prefix > b.prefix ? 1 : b.prefix > a.prefix ? -1 : 0
        );
      },
      error => {
        console.error(error);
      }
    );
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  head: {
    title: {
      inner: 'Complete registration now to buy online',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Complete registration now to buy online | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Complete registration now to buy online | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Complete registration now to buy online | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    checkUserName() {
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}auth/checkuser?id=${this.userName}`
      }).then(
        result => {
          this.userExists = result.data.userExists;
        },
        error => {
          console.error(error);
        }
      );
    },
    updateValidation() {
      let index = this.countries.findIndex(x => x.prefix === this.prefix);
      this.dialingMin =
        this.countries[index].maxlength - this.prefix.length - 1;
    },
    sendVerify() {
      this.mobileNumber = this.mobileNumber.startsWith(this.prefix)
        ? this.mobileNumber.substr(this.prefix.length)
        : this.mobileNumber;
      this.failedValidation = false;
      this.numberExists = false;
      this.sending = true;
      this.$http({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}auth/verify?number=${this.prefix +
          this.mobileNumber}`
      }).then(
        result => {
          this.sending = false;
          if (result.data.exists) {
            this.numberExists = result.data.exists;
          } else {
            this.verified = result.data.code.status;
          }
        },
        error => {
          this.sending = false;
          this.failedValidation = true;
          this.$refs.verify.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
          console.error(error);
        }
      );
    },
    verifyCode() {
      this.verifying = true;
      this.$http({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}auth/verified?number=${this.prefix +
          this.mobileNumber}&code=${this.code}`
      }).then(
        result => {
          this.verifying = false;
          this.verified = result.data.status.status;
        },
        error => {
          this.verifying = false;
          console.error(error);
        }
      );
    },
    completeSignUp() {
      this.submitting = true;
      if (this.verified == "approved") {
        this.$http({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}auth/register-complete`,
          data: {
            email: this.user.data[0].email,
            title: this.title,
            dob:
              this.dob.day + "/" + (this.dob.month + 1) + "/" + this.dob.year,
            phoneNumber: this.prefix + this.mobileNumber,
            firstName: this.firstName,
            lastName: this.lastName,
            tag: this.userName,
            registration: this.user.registration
          }
        }).then(
          () => {
            this.submitting = false;
            this.user.registration.status = "complete";
            this.$store.dispatch("fetchUser", this.user);
            if (this.order && this.order.id != undefined) {
              this.$router.push("/checkout");
            } else {
              this.$router.push("/account");
            }
          },
          error => {
            this.submitting = false;
            console.error(error);
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.typetoggle {
  top: 0;
  right: 0em;
  user-select: none;
}

.border-rounded-left-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
