<template>
  <div id="app" class="d-flex">
    <nav id="sidebar" :class="{active: nav}">
      <div class="border-0 d-block dropdown-menu position-static w-100">
        <div class="px-2 text-right mb-3"><button class="btn" @click="nav = false"><font-awesome-icon :icon="['fal', 'times']" size="2x"/></button></div>
        <template v-if="user.loggedIn">
          <router-link class="dropdown-item" :to="{
            name: 'Account',
          }"><font-awesome-icon icon="user-circle" class="mr-2" /> Profile</router-link>
          <button class="btn btn-link dropdown-item" @click="logOut">Logout</button>
        </template>
        <template v-else>
          <div class="px-4 mb-2">
            <div class="row">
              <div class="col-6">
                <router-link :to="{
                  name: 'Login',
                }" class="btn btn-outline-dark btn-block">Login</router-link>
              </div>
              <div class="col-6">
                <router-link :to="{
                  name: 'Register',
                }" class="btn btn-outline-dark btn-block">Join</router-link>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="border-bottom-0 border-left-0 border-right-0 d-block dropdown-menu position-static w-100">
        <router-link class="dropdown-item" to="/">Home</router-link>
        <router-link class="dropdown-item" to="/topup">Topup</router-link>
        <router-link class="dropdown-item" to="/topup/countries">Countries</router-link>
        <a href="/news" class="dropdown-item">News</a>
        <router-link v-for="page in pages" :key="page.id" class="dropdown-item" :to="{
          name: 'Page',
          params: { slug: page.id }
        }">{{ page.title }}</router-link>
      </div>
    </nav>
    <div class="flex-grow-1">
      <div id="nav" class="bg-white p-2">
        <div
          class="container-sm d-flex align-items-center justify-content-between"
        >
          <ul class="nav" ref="mainNav">
            <li class="nav-item dropdown">
              <a
                class="nav-link"
                @click="nav = !nav"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                ><font-awesome-icon icon="bars"
              /></a>
            </li>
          </ul>
          <router-link to="/" class="logo w-100"><logo /></router-link>
          <ul class="nav">
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Checkout',
                }"
                class="nav-link"
                :class="{'disabled text-black-50': order.id == null || user.loggedIn == false}"
                ><font-awesome-icon icon="shopping-basket"
              /></router-link>
            </li>
          </ul>
        </div>
      </div>
      <router-view />
      <div class="container container-sm px-0 px-md-2">
        <p class="d-flex justify-content-between mb-0 p-3 bg-tertiary">
          <b>Need a hand?</b>
          <b
            ><font-awesome-icon
              :icon="['far', 'envelope']"
              class="text-primary align-middle mr-2"
            />
            <a
              href="mailto:&#109;a&#105;lto&#58;%6&#56;elp&#64;&#116;o%70&#117;%70s&#46;%63om"
              class="text-reset"
              >&#104;e&#108;&#112;&#64;&#116;o&#112;ups&#46;com</a
            ></b
          >
        </p>

        <ul class="list-group list-group-horizontal text-center mb-4">
          <li class="list-group-item flex-grow-1 rounded-0">
            <a
              href="https://www.facebook.com/topups"
              target="_blank"
              class="text-reset"
              ><font-awesome-icon :icon="['fab', 'facebook-f']"
            /></a>
          </li>
          <li class="list-group-item flex-grow-1">
            <a
              href="https://twitter.com/topups"
              target="_blank"
              class="text-reset"
              ><font-awesome-icon :icon="['fab', 'twitter']"
            /></a>
          </li>
          <li class="list-group-item flex-grow-1">
            <a
              href="https://www.instagram.com/topups"
              target="_blank"
              class="text-reset"
              ><font-awesome-icon :icon="['fab', 'instagram']"
            /></a>
          </li>
          <li class="list-group-item flex-grow-1">
            <a
              href="https://www.snapchat.com/add/top.ups"
              target="_blank"
              class="text-reset"
              ><font-awesome-icon :icon="['fab', 'snapchat-ghost']"
            /></a>
          </li>
          <li class="list-group-item flex-grow-1 rounded-0">
            <a
              href="https://www.linkedin.com/company/topups"
              target="_blank"
              class="text-reset"
              ><font-awesome-icon :icon="['fab', 'linkedin']"
            /></a>
          </li>
        </ul>
        <img
          src="/img/topup/worldpay-payment-logos@2x.png"
          alt="WorldPay Logo"
          class="img-fluid mx-auto mb-4 d-block"
          width="202"
        />
      </div>

      <div class="container container-sm">
        <ul class="list-inline small">
          <li class="list-inline-item">
            <a href="/sitemap.xml" class="text-reset" target="_blank">Sitemap</a>
          </li>
          <!-- <li class="list-inline-item"> | </li>
          <li class="list-inline-item"><a href="" class="text-reset">Privacy policy</a></li>
          <li class="list-inline-item"> | </li>
          <li class="list-inline-item"><a href="" class="text-reset">Terms &amp; Conditions</a></li> -->
        </ul>
        <p class="small">&copy; {{ new Date().getFullYear() }} Topups.com</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$theme-colors: (
  "primary": #49c5b1,
  "secondary": #f1f4f4,
  "tertiary": #e1ecec,
  "warning": #ffbe3b
);

$grid-gutter-width: 20px;

$border-color: #BABABA;
$card-border-color: $border-color;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.page-bg {
  height: 60vw;
  object-fit: cover;
  @media screen and (min-width: 576px) {
    height: 30vw;
    object-position: center top;
  }
  & +.container {
    z-index: 1;
    margin-top: -40vw;
    @extend .position-relative;
    @media screen and (min-width: 576px) {
      margin-top: -6.6vw;
    }
  }
}

.logo {
  max-width: 156px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#sidebar {
  min-width: 250px;
  max-width: 100%;
  width: 320px;
  background: #fff;
  transition: all 0.3s;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0px;
  transform: translateX(-100%);
  height: 100vh;
  z-index: 2;
  a {
    &.router-link-exact-active {
      @extend .font-weight-bold;
    }
  }
}

#sidebar.active {
  transform: translateX(0);
  @extend .shadow;
}
</style>

<script>
import { mapGetters } from "vuex";

import logo from "@/components/Logo";

export default {
  components: {
    logo
  },
  data() {
    return {
      nav: false,
      pages: []
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  created() {
    let self = this;

    window.addEventListener("click", function(e) {
      // close menu when clicked outside
      if (
        !self.$refs.mainNav.contains(e.target) &&
        self.$refs.mainNav != undefined
      ) {
        self.nav = false;
      }
    });

    if (this.$env != "development") {
      var Tawk_API = Tawk_API || {};
      (function() {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/5c95ec2dc37db86fcfcf6e7c/default";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  },
  beforeMount() {
    this.getPages();
  },
  methods: {
    logOut() {
      let that = this;
      this.$firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          console.log("logged out");
          // that.$store.dispatch("fetchOrder");
          that.$router.go(0);
        })
        .catch(function(error) {
          // An error happened.
          console.error(error);
        });
    },
    getPages() {
      this.$http({
        method: 'get',
        url: `${process.env.VUE_APP_API_URL}pages?nav=true`
      })
      .then(async result => {
        this.pages = result.data.pages;

      }, error => {
        console.error(error);
      });
    }
  }
};
</script>
