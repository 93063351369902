<template>
  <div class="container-sm mb-3 py-5">
    <div>
      <div class="text-center mb-3">
        <img
          :src="user.photo"
          class="rounded-circle profile-photo"
          :alt="user.data[0].displayName"
        />
      </div>
      <h1 class="text-center">{{ user.data[0].displayName }}</h1>
      <p class="text-center">{{ user.data[0].email }}</p>
      <hr />
      <div class="row">
        <div class="col flex-grow-1">
          <router-link
            :to="{ name: 'Topup' }"
            class="btn btn-primary btn-lg text-white btn-block"
            ><b>Choose a topup</b></router-link
          >
        </div>
        <div class="col flex-grow-1" v-if="order.id">
          <router-link
            :to="{ name: 'Checkout' }"
            class="btn btn-primary btn-lg text-white btn-block"
            ><b>Checkout</b></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Account",
  computed: {
    ...mapGetters({
      user: "user",
      order: "order"
    })
  },
  head: {
    title: {
      inner: 'Your account',
      separator: '|',
      complement: 'Topups.Com'
    },
    meta: [
      { n: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.', id: 'desc' }, // id to replace intead of create element
      // Twitter
      { n: 'twitter:title', c: 'Your account | Topups.Com' },
      { n: 'twitter:description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.'},
      // Google+ / Schema.org
      { ip: 'name', c: 'Your account | Topups.Com' },
      { ip: 'description', c: 'Buy your Top-up online here and stay connected to friends and family around the world with topups.com - Speedy phone recharge service.' },
      // Facebook / Open Graph
      // { p: 'fb:app_id', c: '123456789' },
      { p: 'og:title', c: 'Your account | Topups.Com' },
      // { p: 'og:image', c: 'https://example.com/image.jpg' },
    ],
    link: function() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-photo {
  max-width: 120px;
}
</style>
